import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  FormControl,
  Select,
  Button,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { DataGrid, GridRow } from "@material-ui/data-grid";
import moment from "moment";
import dotenv from "dotenv";
import theMartLogo from "../../assets/images/theMart_logo_new.png";
dotenv.config();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const FetchData = () => {
  const url = process.env.REACT_APP_API;
  const [data, setData] = useState([]);
  const classes = useStyles();
  // const [selectedDate, setSelectedDate] = useState(null);
  const [weekendDates, setweekendDates] = useState([]);
  const [weekendTotals, setWeekendtotals] = useState([]);
  const [formattedDates, setformattedDates] = useState([]);
  const [isWeekendEnable, setisWeekendEnable] = useState(false);
  const [date, setDate] = useState(null);
  const history = useHistory();
  const [days, setDays] = useState(7);
  let WeekDays = [];
  // fetches the all weeks totals
  const getWeekendTotals = async (dates) => {
    let valueArr = [];
    await axios
      .get(url + `/weekendTotals`, { params: { dates: dates } })
      .then((response) => {
        response.data.forEach((value) => {
          valueArr.push(value.total);
        });
        setWeekendtotals(valueArr);
      })
      .catch((err) => console.log(err));
  };
  // fetches the weekend date arrays
  const getWeekendDates = async () => {
    let formatted_dates = [];
    const dates = await axios
      .get(url + `/weekendingDates`)
      .then((response) => {
        response.data.forEach((date) => {
          let tempDate = new moment(date).format("MM-DD-YYYY");
          formatted_dates.push(tempDate);
        });

        setformattedDates(formatted_dates);
        setweekendDates(response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
    return dates;
  };

  // fetch data from the backend and loads into the accordians and graphs
  const getData = async (date) => {
    await axios
      .get(url + `/fetchData?date="${date}"`)
      .then((response) => {
        setData(response.data);
        console.log("****** api response ******");
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Data loading for the bar graph
  const Chartdata = {
    labels: ["Previous Week Total", "This Week total"],
    datasets: [
      {
        data: [data["lastWeekTotal"], data["total_count"]],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    maxBarThickness: 30,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: {
        grid: {
          offset: true,
        },
      },
    },
  };
  // data loading and options for line chart
  const dataLine = {
    labels: [...formattedDates].reverse(),
    datasets: [
      {
        labels: "",
        data: [...weekendTotals].reverse(),
        fill: false,
        backgroundColor: "rgb(34, 44, 53)",
        borderColor: "rgba(34, 44, 53, 0.4)",
      },
    ],
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  useEffect(async () => {
    const date = await getWeekendDates();
    setDate(date[0]);
    await getData(date[0]);
    await getWeekendTotals(date);
    // await disableWeekendData();
  }, []);
  const dateGridColumns = [
    { field: "lobby_name", headerName: "Lobby Name", width: 230 },
    { field: "count", headerName: "People Visited", width: 145 },
  ];
  const lobbyGridColumns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "day", headerName: "Day", width: 120 },
    { field: "count", headerName: "People Visited", width: 145 },
  ];
  const handleDateChange = (e) => {
    if (e.target.value === "None") {
      setData([]);
    } else {
      setDate(e.target.value);
      getData(e.target.value);
    }
  };

  const HandleCheckBoxEvent = async (e) => {
    setisWeekendEnable(e.target.checked);
    localStorage.setItem("isWeekendEnable", e.target.checked || false);
    await disableWeekendData(date);
  };
  const disableWeekendData = async (date) => {
    const weekendStatus = localStorage.getItem("isWeekendEnable");
    if (weekendStatus === "true") {
      setDays(5);
      await axios
        .get(url + `/disableWeekend?date="${date}"`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(url + `/fetchData?date="${date}"`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log(days);
  return (
    <>
      <div className="p-3 text-center">
        <img className="logo" src={theMartLogo} />
      </div>
      <Grid container>
        <Grid item md={6} xs={12} lg={6}>
          <div className="p-3">
            <h2>Pedestrian Counts Dashboard</h2>
          </div>
        </Grid>
        <Grid item md={2} xs={12} lg={2}>
          <div className="p-3 text-right">
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                history.push("/saturdayData");
              }}
            >
              Saturday Data
            </Button>
          </div>
        </Grid>
        <Grid item md={2} xs={12} lg={2}>
          <div className="p-3 text-right">
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                history.push("/monthlyAverageData");
              }}
            >
              Monthly Average
            </Button>
          </div>
        </Grid>
        <Grid item md={2} xs={12} lg={2}>
          <div className="p-3 text-right">
            <Button
              variant="contained"
              color="info"
              onClick={() => {
                history.push("/customRangeData");
              }}
            >
              Custom date range
            </Button>
          </div>
        </Grid>
      </Grid>
      <div className="p-4">
        <Grid container>
          <Grid item md={4} xs={12} lg={6}>
            <div>
              <label className="mt-1">Select Weekending Date:</label>
              <FormControl className={classes}>
                <Select
                  native
                  onChange={handleDateChange}
                  inputProps={{
                    name: "WeekendingDates",
                    id: "age-native-simple",
                  }}
                  className="ml-2 "
                >
                  {weekendDates.map((dt) => {
                    return (
                      <option value={dt}>
                        {new moment(dt).format("MM-DD-YYYY")}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="mt-2">
              <input
                type="checkbox"
                onClick={HandleCheckBoxEvent}
                value="Disable Weekend Data"
              />
              <label className="ml-2">Exclude weekend counts</label>
            </div>
            <div className="mt-2">
              <label>Change from prior week:</label>
              <label className="ml-1">
                <b>
                  {!data.lastWeekTotal
                    ? "--"
                    : (
                        ((data.total_count - data.lastWeekTotal) * 100) /
                        data.lastWeekTotal
                      ).toFixed(2)}
                  %
                </b>
              </label>
            </div>
            <div className="total-label ">
              <label>Average weekday count:</label>
              <label className="ml-1">
                <b>
                  {!data.total_count
                    ? "--"
                    : parseInt(data.total_count / days).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                </b>
              </label>
            </div>
            <div className="total-label">
              <label>Total count for the week:</label>
              <label className="ml-1">
                <b>
                  {!data.total_count
                    ? "--"
                    : data.total_count.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                </b>
              </label>
            </div>
            <Grid container spacing={10}>
              <Grid item md={5} xs={12} lg={6}>
                <div className="chart-bar mt-2">
                  <Bar
                    data={Chartdata}
                    options={options}
                    height={150}
                    width={300}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} xs={12} lg={6}>
            <div className="chart-line">
              <Line data={dataLine} options={optionsLine} />
            </div>
          </Grid>
        </Grid>
        <div></div>
        <div className="mt-5">
          <Grid container spacing={2} justify="center">
            <Grid item md={5} xs={12} lg={6}>
              {Object.values(data.date_records || {}).map((dt) => {
                return (
                  <Accordion className="mt-2">
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {dt.date}
                      </Typography>
                      <Typography className={classes.heading}>
                        {dt.day}
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {dt.dateWiseSum.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                          rows={dt.data.map((d, index) => {
                            return { ...d, id: index };
                          })}
                          columns={dateGridColumns}
                          autoHide={true}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              {Object.values(data.lobby_records || {}).map((dt) => {
                return (
                  <Accordion className="mt-2">
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        {dt.lobby_name}
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {dt.sectionWiseSum.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ height: 400, width: "100%" }}>
                        <DataGrid
                          rows={dt.redifinedData.map((d, index) => {
                            return { ...d, id: index };
                          })}
                          columns={lobbyGridColumns}
                          autoHide={true}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default FetchData;
