import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import dotenv from "dotenv";
import theMartLogo from "../../assets/images/theMart_logo_new.png";
dotenv.config();
const API_URL = process.env.REACT_APP_API;
const CsvReader = () => {
  var list = [];
  const [data, setData] = useState([]);
  const { handleSubmit } = useForm();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const readFile = (file) => {
    var reader = new FileReader();
    reader.onload = (file) => {
      let data = file.target.result;
      const dataLines = data.split(/\r\n|\n/);
      for (let i = 1; i < dataLines.length; i++) {
        const dataValidation = dataLines[i].split(',');
        if(dataValidation[0] !== ""){
          list.push(dataLines[i]);
        }
      }
      setData(list);
    };
    reader.readAsText(file);
  };

  const onUpload = (e) => {
    let files = e.target.files[0];
    for (let i = 0; i < e.target.files.length; i++) {
      readFile(files);
    }
  };

  const onSubmit = () => {
    var formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append("files", data[key]);
    }
    axios
      .post(API_URL + "/csvData", data, {
        header: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status === 500) {
          setAlert("failed");
        }if(res.status === 204){
          setAlert('updated');
        } 
        else {
          setAlert("success");
        }
      })
      .catch((err) =>{
         setAlert("failed");
       } );
    setData([]);
    document.getElementById("file").value = null;
  };
  return (
    <>
      <div className="p-3 text-center">
        <img className="logo" src={theMartLogo} />
      </div>
      <div className="p-3">
        <h2>Pedestrian Counter Dashboard</h2>
      </div>
      <div className="p-4">
        <h5 className="mt-3 ">Upload file here</h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="fileUpload"
          name="fileUpload"
        >
          <div className="upload-data">
            <label>Select the file to upload:</label>
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              id="file"
              name="file"
              className="mt-2 ml-2 primary"
              onChange={(e) => onUpload(e)}
              required={true}
            />
            <Button variant="contained" color="primary" type="submit">
              {" "}
              Upload File
            </Button>
          </div>
          {/* <button type="submit" className="btn-primary">Upload File</button> */}
        </form>
        <div className="dashboard mt-3 mb-3">
          <label>To view current dashboard:</label>{" "}
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              history.push("/");
            }}
          >
            View weekly report here!
          </Button>
        </div>
        { 
        alert === null ? (
          <div></div>
        ) : alert === "success" ? (
          <Alert severity="success">Data added successfully.</Alert>
        ) : alert === "updated"?<Alert severity="info">Data updated successfully.</Alert>:<Alert severity="error">Something went wrong.</Alert>
        }
        <div className="mt-3"></div>
        {data.length === 0 ? (
          <div className="text-center">
            <b>No data loaded</b>
          </div>
        ) : (
          <div>
            <h6>Below data will be uploaded</h6>
          <table className="table table-striped mt-3">
            <thead className="thead-dark">
              <tr>
                <th>Camera Name</th>
                <th>Camera IP</th>
                <th>Counter Name</th>
                <th>People's count</th>
                <th>Date and time</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dt) => {
                var refined = dt.split(",");
                return (
                  <tr>
                    <td>{refined[0]}</td>
                    <td>{refined[1]}</td>
                    <td>{refined[2]}</td>
                    <td>{refined[3]}</td>
                    <td>{refined[4]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
        )}
      </div>
    </>
  );
};
export default CsvReader;
