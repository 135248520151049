import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  FormControl,
  Button,
  Select,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import moment from "moment";
import theMartLogo from "../../assets/images/theMart_logo_new.png";
import { DataGrid, GridRow } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const MonthlyAverageData = () => {
  const url = process.env.REACT_APP_API;
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [year, setYear] = useState(2019);
  const [months, setMonths] = useState([]);
  const [totalCounts, setTotalCounts] = useState();
  const [years, setYears] = useState([
    2019, 2020, 2021, 2022, 2023, 2024, 2025,
  ]);

  useEffect(() => {
    getDataByYear(new Date(year, 0, 1), new Date(year, 11, 31, 23, 59, 59));
  }, [year]);

  const getDataByYear = async (startDate, endDate) => {
    try {
      const response = await axios.get(
        `${url}/customRangeData?startDate="${startDate}"&endDate="${endDate}`
      );
      setData(response.data);
      setMonths(response.data.date_records);
      setTotalCounts(response.data.total_count);
    } catch (error) {
      //console.log(error);
    }
  };

  const lobbyDataColumns = [
    { field: "lobbyName", headerName: "Lobby Name", width: 230 },
    { field: "lobbySum", headerName: "People Visited", width: 145 },
  ];

  const renderAccordion = () => {
    const monthData = {};

    months.forEach((month) => {
      const monthLabel = moment(month.date, "MM-DD-YYYY").format("MMMM");
      const monthSum = month.dateWiseSum;

      if (!monthData[monthLabel]) {
        monthData[monthLabel] = {
          dateWiseSum: monthSum,
          data: month.data.reduce((acc, entry) => {
            if (acc[entry.lobby_name]) {
              acc[entry.lobby_name].push(entry);
            } else {
              acc[entry.lobby_name] = [entry];
            }
            return acc;
          }, {}),
        };
      } else {
        monthData[monthLabel].dateWiseSum += monthSum;
        month.data.forEach((entry) => {
          if (monthData[monthLabel].data[entry.lobby_name]) {
            monthData[monthLabel].data[entry.lobby_name].push(entry);
          } else {
            monthData[monthLabel].data[entry.lobby_name] = [entry];
          }
        });
      }
    });

    return Object.keys(monthData).map((monthLabel) => {
      const month = monthData[monthLabel];
      const monthSum = month.dateWiseSum;
      const averageMonthSum = (monthSum / 12).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });

      const lobbyData = Object.keys(month.data).map((lobbyName) => {
        const lobbyEntries = month.data[lobbyName];
        const lobbyCountArray = lobbyEntries.map((entry) =>
          parseInt(entry.count.replace(/,/g, ""))
        );
        const lobbySum = lobbyCountArray.reduce((sum, count) => sum + count, 0);

        return {
          lobbyName,
          lobbySum,
        };
      });

      return (
        <Accordion key={monthLabel} className="mt-2">
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{monthLabel}</Typography>
            <Typography className={classes.secondaryHeading}>
              {averageMonthSum}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={lobbyData.map((d, index) => {
                  return { ...d, id: index };
                })}
                columns={lobbyDataColumns}
                autoHide={true}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const handleYearChange = (e) => {
    setYear(Number(e.target.value));
  };

  return (
    <>
      <div className="p-3 text-center">
        <img className="logo" src={theMartLogo} />
      </div>
      <Grid container>
        <Grid item md={12} xs={12} lg={12}>
          <div className="p-3">
            <h2>Pedestrian Counts Dashboard</h2>
          </div>
        </Grid>
      </Grid>
      {/* Your other JSX code */}
      <div className="p-4">
        <Grid container>
          <Grid item md={4} xs={12} lg={6}>
            <div>
              <label>Select year:</label>
              <label className="ml-1">
                <Select
                  native
                  value={year}
                  onChange={handleYearChange}
                  inputProps={{
                    name: "WeekendingDates",
                    id: "age-native-simple",
                  }}
                  className="ml-2"
                >
                  {years.map((dt) => {
                    return (
                      <option key={dt} value={dt}>
                        {dt}
                      </option>
                    );
                  })}
                </Select>
              </label>
              {/* Your other JSX code */}
              <div className="total-label">
                <label>Total count:</label>
                <label className="ml-1">
                  <b>
                    {/* {!data.total_count
                      ? "--"
                      : data.total_count.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })} */}
                    {totalCounts ? totalCounts : "--"}
                  </b>
                </label>
              </div>
            </div>
          </Grid>
        </Grid>
        {!data ? (
          <div></div>
        ) : (
          <div className="mt-5">
            <Grid container spacing={5}>
              <Grid item md={6} xs={12} lg={6}>
                <div className={classes.root}>
                  <div>
                    <Typography variant="h5" className="text-center mb-4">
                      Monthly Average Data
                    </Typography>
                    <div>{renderAccordion()}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};

export default MonthlyAverageData;
