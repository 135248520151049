import logo from "./logo.svg";
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import "./App.css";
import CsvReader from "./components/csvReader/index";
import FetchData from "./components/fetchData/index";
import CustomRangeData from "./components/customRangeData";
import MonthlyAverageData from "./components/montlyAverageData";
import SaturdayData from "./components/saturdayData";
function App() {
  return (
    <div className="App">
      <Router>
        <Link to="/"></Link>
        <Route path="/" exact component={FetchData} />
        <Route path="/customRangeData" component={CustomRangeData} />
        <Route path="/upload" component={CsvReader} />
        <Route path="/monthlyAverageData" component={MonthlyAverageData} />
        <Route path="/saturdayData" component={SaturdayData} />
      </Router>
    </div>
  );
}

export default App;
