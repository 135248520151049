import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { Bar, Line } from "react-chartjs-2";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  FormControl,
  Button,
} from "@material-ui/core";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ExpandMore, Label, LabelOff, LabelOutlined } from "@material-ui/icons";
import { DataGrid, GridRow } from "@material-ui/data-grid";
import moment from "moment";
import dotenv from "dotenv";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import theMartLogo from "../../assets/images/theMart_logo_new.png";
dotenv.config();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const CustomRangeData = () => {
  const url = process.env.REACT_APP_API;
  const [data, setData] = useState([]);
  const classes = useStyles();
  // const [selectedDate, setSelectedDate] = useState(null);
  const [Dates, setDates] = useState([]);
  const [dateWiseTotals, setDateWisetotals] = useState([]);
  const [dateLabel, setDateLabel] = useState("");
  const [weekendDates, setWeekendDates] = useState([]);
  const [weekendTotals, setWeekendTotals] = useState([]);
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  // fetches the all weeks totals
  //   const getWeekendTotals = async (dates) => {
  //     let valueArr = [];
  //     await axios
  //       .get(url + `/weekendTotals`, { params: { dates: dates } })
  //       .then((response) => {
  //         response.data.forEach((value) => {
  //           valueArr.push(value.total);
  //         });
  //         setWeekendtotals(valueArr);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   // fetches the weekend date arrays
  //   const getWeekendDates = async () => {
  //     let formattedDates = [];
  //     const dates = await axios
  //       .get(url + `/weekendingDates`)
  //       .then((response) => {
  //         setweekendDates(response.data);
  //         return response.data;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         throw err;
  //       });
  //     return dates;
  //   };

  // fetch data from the backend and loads into the accordians and graphs
  const getData = async (startDate, endDate) => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    let valueArr = [];
    let dateArr = [];
    let weekendValueArr = [];
    let weekendDateArr = [];
    await axios
      .get(
        url + `/customRangeData?startDate="${startDate}"&endDate="${endDate}`
      )
      .then((response) => {
        response.data.date_records.forEach((value) => {
          let tempDate = new Date(value.date);
          valueArr.push(value.dateWiseSum);
          dateArr.push(new moment(tempDate).format("MM-DD-YYYY"));
        });
        response.data.weekend_data.forEach((value) => {
          let temp = new Date(value.date);
          weekendValueArr.push(value.total);
          weekendDateArr.push(new moment(temp).format("MM-DD-YYYY"));
        });
        setData(response.data);
        setDateWisetotals(valueArr);
        setDates(dateArr);
        setWeekendDates(weekendDateArr);
        setWeekendTotals(weekendValueArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Data loading for the bar graph
  const Chartdata = {
    labels: ["Previous Week Total", "This Week total"],
    datasets: [
      {
        data: [data["lastWeekTotal"], data["total_count"]],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    maxBarThickness: 30,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: {
        grid: {
          offset: true,
        },
      },
    },
  };
  //   data loading and options for line chart
  const dataLine = {
    labels: [...weekendDates],
    datasets: [
      {
        labels: "",
        data: [...weekendTotals],
        fill: false,
        backgroundColor: "rgb(34, 44, 53)",
        borderColor: "rgba(34, 44, 53, 0.4)",
      },
    ],
  };

  const optionsLine = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const dateGridColumns = [
    { field: "lobby_name", headerName: "Lobby Name", width: 230 },
    { field: "count", headerName: "People Visited", width: 145 },
  ];
  const lobbyGridColumns = [
    { field: "date", headerName: "Date", width: 150 },
    { field: "day", headerName: "Day", width: 120 },
    { field: "count", headerName: "People Visited", width: 145 },
  ];
  const handleDateChange = (start, end) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var startDate = new Date(start);
    var endDate = new Date(end);
    setDateLabel(
      `${
        monthNames[startDate.getMonth()]
      } ${startDate.getDate()}, ${startDate.getFullYear()} - ${
        monthNames[endDate.getMonth()]
      } ${endDate.getDate()}, ${endDate.getFullYear()}`
    );
    setDate1(startDate);
    setDate2(endDate);
    getData(startDate, endDate);
  };

  let start = new Date();
  let day_7 = new Date();
  let yesterday = new Date();
  let day_30 = new Date();
  let previous_month_start = new Date();
  let previous_month_end = new Date();
  let ranges = {
    "Last 15 Days": [day_7.setDate(start.getDate() - 15), start],
    "Last 30 Days": [day_30.setDate(start.getDate() - 30), start],
    "Last 90 Days": [day_30.setDate(start.getDate() - 90), start],
    "Last Month": [
      previous_month_start.setMonth(previous_month_start.getMonth() - 1),
      previous_month_end,
    ],
    "Last 3 Months": [
      previous_month_start.setMonth(previous_month_start.getMonth() - 1),
      previous_month_end,
    ],
    "Last 6 Months": [
      previous_month_start.setMonth(previous_month_start.getMonth() - 6),
      previous_month_end,
    ],
  };
  const HandleCheckBoxEvent = async (e) => {
    localStorage.setItem(
      "isWeekendEnableCustomRange",
      e.target.checked || false
    );
    await disableWeekendData();
  };
  const disableWeekendData = async () => {
    const weekendStatus = localStorage.getItem("isWeekendEnableCustomRange");
    let valueArr = [];
    let dateArr = [];
    let weekendValueArr = [];
    let weekendDateArr = [];
    if (weekendStatus === "true") {
      await axios
        .get(
          url +
            `/disableWeekendCustomRange?startDate="${date1}"&endDate="${date2}`
        )
        .then((response) => {
          response.data.date_records.forEach((value) => {
            let tempDate = new Date(value.date);
            valueArr.push(value.dateWiseSum);
            dateArr.push(new moment(tempDate).format("MM-DD-YYYY"));
          });
          response.data.weekend_data.forEach((value) => {
            let temp = new Date(value.date);
            weekendValueArr.push(value.total);
            weekendDateArr.push(new moment(temp).format("MM-DD-YYYY"));
          });
          setData(response.data);
          setDateWisetotals(valueArr);
          setDates(dateArr);
          setWeekendDates(weekendDateArr);
          setWeekendTotals(weekendValueArr);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(url + `/customRangeData?startDate="${date1}"&endDate="${date2}`)
        .then((response) => {
          response.data.date_records.forEach((value) => {
            let tempDate = new Date(value.date);
            valueArr.push(value.dateWiseSum);
            dateArr.push(new moment(tempDate).format("MM-DD-YYYY"));
          });
          response.data.weekend_data.forEach((value) => {
            let temp = new Date(value.date);
            weekendValueArr.push(value.total);
            weekendDateArr.push(new moment(temp).format("MM-DD-YYYY"));
          });
          setData(response.data);
          setDateWisetotals(valueArr);
          setDates(dateArr);
          setWeekendDates(weekendDateArr);
          setWeekendTotals(weekendValueArr);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className="p-3 text-center">
        <img className="logo" src={theMartLogo} />
      </div>
      <Grid container>
        <Grid item md={12} xs={12} lg={12}>
          <div className="p-3">
            <h2>Pedestrian Counts Dashboard</h2>
          </div>
        </Grid>
      </Grid>
      <div className="p-4">
        <Grid container>
          <Grid item md={4} xs={12} lg={6}>
            <div>
              <label className="mt-1">Select Weekending Date:</label>
              <FormControl className={classes}>
                <DateRangePicker
                  initialSettings={{
                    startDate: start,
                    endDate: start,
                    ranges: ranges,
                  }}
                  onCallback={handleDateChange}
                >
                  <div className="ml-2">
                    <Button variant="outlined" color="default">
                      {!dateLabel ? "Select custom date range" : dateLabel}
                    </Button>
                  </div>
                </DateRangePicker>
              </FormControl>
            </div>
            <div className="total-label">
              <label>Total count:</label>
              <label className="ml-1">
                <b>
                  {!data.total_count
                    ? "--"
                    : data.total_count.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                </b>
              </label>
            </div>
            {/* <div className="mt-2">
              <input
                type="checkbox"
                onClick={HandleCheckBoxEvent}
                value="Disable Weekend Data"
              />
              <label className="ml-2">Disable Weekend data</label>
            </div> */}
          </Grid>
        </Grid>
        <div></div>
        {!data ? (
          <div></div>
        ) : (
          <div className="mt-5">
            <Grid container spacing={5}>
              <Grid item md={6} xs={12} lg={6}>
                {Object.values(data.lobby_records || {}).map((dt) => {
                  return (
                    <Accordion className="mt-2">
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {dt.lobby_name}
                        </Typography>
                        <Typography className={classes.secondaryHeading}>
                          {dt.sectionWiseSum.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={dt.redifinedData.map((d, index) => {
                              return { ...d, id: index };
                            })}
                            columns={lobbyGridColumns}
                            autoHide={true}
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Grid>
              <Grid item md={12} xs={12} lg={12}>
                {!data ? (
                  <div></div>
                ) : (
                  <div className="chart-line-customrange">
                    <Line
                      data={dataLine}
                      options={optionsLine}
                      className="chart-js-custom-range"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};
export default CustomRangeData;
